







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    PInsFooterLayout: () => import('./layout/pc/InsFooterLayout.vue'),
    MInsFooterLayout: () => import('./layout/mobile/InsFooterLayout.vue')
    // footerLayout: () => import('./layout/pc/InsFooterLayout.vue')
  }
})
export default class InsFooter extends Vue {
}
